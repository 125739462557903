<template>
  <div>
    <!-- Breadcrumb -->
    <div class="row content-header">
      <div class="content-header-left mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2
              class="content-header-title float-left mb-0"
              v-text="$t('students')"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="card card-profile">
          <img
            src="/view-student-default-banner.jpg"
            class="card-img-top"
          >
          <div class="card-body">
            <div class="profile-image-wrapper">
              <div class="profile-image p-0">
                <span
                  class="b-avatar badge-light rounded-circle"
                  style="width: 114px; height: 114px;"
                >
                  <span class="b-avatar-img">
                    <img
                      src="/default-avatar.jpg"
                    >
                  </span>
                </span>
              </div>
            </div>
            <h3
              v-if="student.name"
              v-text="student.name"
            />
            <h6
              class="text-muted"
              v-text="student.email"
            />
            <span
              class="badge profile-badge badge-light-primary"
              v-text="student.current_level"
            />
            <hr class="mb-2">
            <div
              class="d-flex justify-content-between align-items-center"
            >
              <div>
                <h6 class="text-muted font-weight-bolder">
                  Total Series
                </h6>
                <h3
                  class="mb-0"
                  v-text="student.total_series"
                />
              </div>
              <div>
                <h6 class="text-muted font-weight-bolder">
                  Total Class
                </h6>
                <h3
                  class="mb-0"
                  v-text="student.total_class"
                />
              </div>
              <div>
                <h6 class="text-muted font-weight-bolder">
                  Remaining Load
                </h6>
                <h3
                  class="mb-0"
                  v-text="student.remaining_load"
                />
              </div>
            </div>
          </div>
        </div>
        <p
          v-if="student.last_login"
        >
          Last Login:
          <span
            v-text="student.last_login"
          />
        </p>
      </div>
      <div class="col-md-6 col-lg-8">
        <!-- Table Container Card -->
        <b-overlay
          :show="enrollments.loading"
          opacity="0.10"
          rounded="sm"
        >
          <h1>Enrollments</h1>
          <b-card
            no-body
            class="mb-0"
          >
            <b-table
              responsive
              :items="enrollments.items"
              :fields="enrollments.fields"
              class="mb-0"
              :tbody-tr-class="enrollmentRowClass"
            >
              <!-- Column: id -->
              <template #cell(id)="data">
                <span
                  class="align-middle ml-50"
                  v-text="data.item.count"
                />
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item
                    @click="showEnrollmentSchedulesModal(data.item)"
                  >
                    <feather-icon icon="EyeIcon" />
                    <span class="align-middle ml-50">{{ $t('view_schedules') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <template slot="footer">
              <b-pagination
                v-model="enrollments.filter.page"
                :per-page="enrollments.perPage"
                hide-goto-end-buttons
                :total-rows="enrollments.totalPage"
                align="center"
                @change="loadData"
              />
            </template>
          </b-card>
        </b-overlay>
      </div>
    </div>

    <b-modal
      ref="view-schedules-modal"
      centered
      no-close-on-backdrop
      size="xl"
      title="Schedules"
      ok-title="Close"
      :ok-only="true"
    >
      <!-- Table Container Card -->
      <b-overlay
        :show="schedules.loading"
        opacity="0.10"
        rounded="sm"
      >
        <b-card
          no-body
          class="mb-0"
        >
          <b-table
            responsive
            :items="schedules.items"
            :fields="schedules.fields"
            class="mb-0"
            :tbody-tr-class="enrollmentRowClass"
          >
            <!-- Column: id -->
            <template #cell(id)="data">
              <span
                class="align-middle ml-50"
                v-text="data.item.count"
              />
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  @click="showEditScheduleModal(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{ $t('edit_schedule') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteStudyRoomModal(data.item)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $t('delete_study_room') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <template slot="footer">
            <b-pagination
              v-model="schedules.filter.page"
              :per-page="schedules.perPage"
              hide-goto-end-buttons
              :total-rows="schedules.totalPage"
              align="center"
              @change="loadScheduleData"
            />
          </template>
        </b-card>
      </b-overlay>
    </b-modal>
    <b-modal
      ref="edit-schedule-modal"
      cancel-variant="outline-secondary"
      ok-title="Update"
      cancel-title="Close"
      centered
      no-close-on-backdrop
      size="lg"
      title="Edit Schedule"
      @ok="editScheduleHandleOk"
      @close="resetEditScheduleFormData"
      @cancel="resetEditScheduleFormData"
    >
      <validation-observer ref="simpleRules">
        <b-form @submit.stop.prevent="scheduleHandleSubmit">
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('scheduled_at')"
                  rules="required"
                >
                  <label>{{ $t('scheduled_at') }}</label>
                  <flat-pickr
                    v-model="scheduleFormData.scheduled_at"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('start_time')"
                  rules="required"
                >
                  <label>{{ $t('start_time') }}</label>
                  <b-form-timepicker
                    v-model="scheduleFormData.start_time"
                    show-seconds
                    locale="en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('end_time')"
                  rules="required"
                >
                  <label>{{ $t('end_time') }}</label>
                  <b-form-timepicker
                    v-model="scheduleFormData.end_time"
                    show-seconds
                    locale="en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('end_time')"
                  rules="required"
                >
                  <label>{{ $t('teacher') }}</label>
                  <v-select
                    v-model="scheduleFormData.tutor_id"
                    :options="scheduleFormData.teachers"
                    class="w-100"
                    :reduce="val => val.id"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      ref="delete-study-room-modal"
      cancel-variant="outline-secondary"
      ok-title="Delete"
      cancel-title="Close"
      centered
      no-close-on-backdrop
      size="xl"
      :title="$t('delete_study_room')"
      @ok="deleteStudyRoomHandleOk"
      @close="resetFormData"
      @cancel="resetFormData"
    >
      <b-form @submit.stop.prevent="deleteStudyRoomHandleSubmit">
        <b-row>
          <b-col
            cols="12"
            md="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group>
              <b-form-checkbox-group
                v-model="formData.selected_study_rooms"
                style="column-count: 5;"
                class="levels-checkbox-container"
                stacked
                :options="studyOptions"
                value-field="id"
                text-field="type"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

  </div>
</template>

<script>

import {
  required,
} from '@validations'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import {
  BRow,
  BCol,
  BCard,
  BTable,
  BOverlay,
  BModal,
  BForm,
  BFormGroup,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormTimepicker,
} from 'bootstrap-vue'
import Student from '@/models/Student'
import Enrollment from '@/models/Enrollment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'StudentDetails',
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BOverlay,
    BModal,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormTimepicker,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      enrollments: {
        items: [],
        fields: [
          'id',
          'level',
          'series',
          'date_start',
          'date_end',
          'remaining_schedules',
          'actions',
        ],
        loading: true,
        perPage: 10,
        totalPage: 0,
        filter: {
          page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
        },
      },
      schedules: {
        items: [],
        fields: [
          'id',
          'scheduled_at',
          'start_time',
          'end_time',
          'book_code',
          'book_title',
          'book_content_status',
          'study_room_count',
          'actions',
        ],
        loading: false,
        perPage: 10,
        totalPage: 0,
        filter: {
          page: 1,
        },
      },
      student: {
        id: 0,
      },
      enrollment: {
        id: 0,
      },
      studyOptions: [],
      formData: {
        id: '',
        selected_study_rooms: [],
      },
      scheduleFormData: {
        id: '',
        tutor_id: '',
        scheduled_at: '',
        start_time: '',
        end_time: '',
      },
    }
  },
  mounted() {
    this.student.id = this.$route.params.id
    this.loadData(this.enrollments.filter.page)
  },
  methods: {
    async loadData(page = 1) {
      this.enrollments.loading = true
      const searchParams = {}
      searchParams.page = page
      Object.keys(this.enrollments.filter).forEach(e => {
        if (this.enrollments.filter[e] !== null && this.enrollments.filter[e].length > 0) {
          searchParams[e] = this.enrollments.filter[e]
        }
      })
      this.$router.replace({ query: searchParams })
      const response = await Student.view(this.student.id, page)
      this.student = response.data.meta.student
      this.enrollments.items = response.data.data
      this.enrollments.totalPage = response.data.meta.pagination.total
      this.enrollments.perPage = response.data.meta.pagination.per_page
      this.enrollments.loading = false
    },
    enrollmentRowClass(item, type) {
      if (type === 'row' && item.is_expired === true) {
        return 'row-schedule-expired'
      }
      return ''
    },
    async showEnrollmentSchedulesModal(enrollmentId) {
      this.enrollment = enrollmentId
      this.$refs['view-schedules-modal'].show()
      this.schedules.filter.page = 1
      this.loadScheduleData()
    },
    async loadScheduleData(page = 1) {
      this.schedules.loading = true
      const searchParams = {}
      searchParams.page = page
      Object.keys(this.schedules.filter).forEach(e => {
        if (this.schedules.filter[e] !== null && this.schedules.filter[e].length > 0) {
          searchParams[e] = this.schedules.filter[e]
        }
      })
      const response = await Enrollment.view(this.enrollment.id, page)
      this.schedules.items = response.data.data
      this.schedules.totalPage = response.data.meta.pagination.total
      this.schedules.perPage = response.data.meta.pagination.per_page
      this.schedules.loading = false
    },
    resetEditScheduleFormData() {
      this.scheduleFormData = {
        id: '',
        tutor_id: '',
        scheduled_at: '',
        start_time: '',
        end_time: '',
      }
    },
    async showEditScheduleModal(data) {
      this.resetEditScheduleFormData()
      try {
        const response = await Student.schedule(data.id)
        this.scheduleFormData = response.data
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        })
      }
      this.$refs['edit-schedule-modal'].show()
    },
    editScheduleHandleOk(e) {
      // Prevent modal from closing
      e.preventDefault()
      // Trigger submit handler
      this.scheduleHandleSubmit()
    },
    async scheduleHandleSubmit() {
      const validator = await this.$refs.simpleRules.validate()

      if (validator) {
        try {
          const response = await Student.updateSchedule(this.scheduleFormData)
          this.$swal({
            title: 'Success!',
            text: response.data,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.loadScheduleData(this.schedules.filter.page)
          this.$refs['edit-schedule-modal'].hide()
          this.resetEditScheduleFormData()
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: e.message,
            },
          })
        }
      }
    },
    showDeleteStudyRoomModal(item) {
      if (item.study_room_count > 0) {
        this.studyOptions = item.study_rooms
        this.formData.id = item.id
        this.formData.selected_study_rooms = []
        this.$refs['delete-study-room-modal'].show()
      }
    },
    deleteStudyRoomHandleOk(e) {
      // Prevent modal from closing
      e.preventDefault()
      // Trigger submit handler
      this.deleteStudyRoomHandleSubmit()
    },
    resetFormData() {
      this.formData = {
        id: '',
        selected_study_rooms: [],
      }
    },
    async deleteStudyRoomHandleSubmit() {
      const response = await Student.deleteStudyRoom(this.formData)
      this.$swal({
        title: 'Success!',
        text: response.data,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
      this.loadScheduleData(this.schedules.filter.page)
      this.$refs['delete-study-room-modal'].hide()
      this.resetFormData()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.levels-checkbox-container .custom-checkbox, .times-checkbox-container .custom-checkbox {
  margin-bottom: 5px;
}
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
